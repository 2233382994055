import { Component, OnInit } from '@angular/core';
import { DoIQualifyFormModel } from '../../models/DoIQualifyForm';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { DoIQualifyService } from 'src/app/services/do-i-qualify.service';
import { DoIQualifyCarrier } from 'src/app/models/DoIQualifyCarrier';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/models/AppGlobals';

@Component({
  selector: 'app-do-i-qualify',
  templateUrl: './do-i-qualify.component.html',
  styleUrls: ['./do-i-qualify.component.css']
})
export class DoIQualifyComponent implements OnInit {
  public Step: number = 0;
  public ResponseData: ResponseModel[] = [];
  public ApplicationData: SendApplicationModel = null;
  public QuestionsData: any[] = [];
  public FormValid: boolean = false;
  public loading: boolean = false;
  public FormErrors: any = null;
  public IsCheckNowClicked: boolean = false;
  public ScrollForError: boolean = false;
  public IsFormError: boolean = false;
  public CarrierQuestionsLength: any;

  constructor(
    private animateScrollService: NgAnimateScrollService,
    private _AppGlobals: AppGlobals,
    public _DoIQualifyFormModel: DoIQualifyFormModel,
    public _DoIQualifyService: DoIQualifyService,
    public _DoIQualifyCarrier: DoIQualifyCarrier,
    public router:Router
  ) {  }



  ngOnInit() {
    window.scroll(0,0);

    window.addEventListener('click', (evt: any) => {
      if (evt.target.classList.contains('modal_qualify_overlay')) {
        this.modalClose();
      }
    });

    this.getQuestions();
  }


  getQuestions() {
    this.loading = true;
    let carrier_id = this._DoIQualifyFormModel.CarrierId;
    let product_id = this._DoIQualifyFormModel.ProductId;

    if(carrier_id != null && product_id != null) {
      let data = { 'carrier_id': carrier_id, 'product_id': product_id };
      // let data = { 'carrier_id': 20, 'product_id': 71 };
      this._DoIQualifyService.GetQuestions(data).subscribe(
        result => {
          this.CarrierQuestionsLength=result["data"]['carrier_questions'].length
          if(result["success"]) {
            let data = result["data"];
            if(data['carrier_questions'] != undefined && data['carrier_questions'].length != 0) {
              for(let i = 0; i < data['carrier_questions'].length; i++) {
                if(data['carrier_questions'][i]['type'] == 'nested') {
                  this.QuestionsData.push({
                    'sl': i+1, 
                    'question_id': data['carrier_questions'][i]['question_id'],
                    'question': data['carrier_questions'][i]['question'],
                    'nested_questions': data['carrier_questions'][i]['nested_questions'],
                    'type': data['carrier_questions'][i]['type'],
                  });
                }
                else {
                  this.QuestionsData.push({
                    'sl': i+1, 
                    'question_id': data['carrier_questions'][i]['question_id'],
                    'question': data['carrier_questions'][i]['question'],
                    'options': data['carrier_questions'][i]['options'],
                    'type': data['carrier_questions'][i]['type'],
                  });
                }
                
              }
            }
          }
          this.loading = false;
        }
      );
    }
  }

  modalShow() {
    let qualifyModal: any = document.querySelector('#qualifyModal');

    qualifyModal.classList.add('show');
  }

  modalClose() {
    let qualifyModal: any = document.querySelector('#qualifyModal');

    qualifyModal.classList.remove('show');
  }

  getSelectedAnswer(question_id) {
    let __index = this.ResponseData.findIndex(x => x.question_id == question_id);
    if(__index != -1) {
      return this.ResponseData[__index].answer;
    }

    return null;
  }

  SelectOption(question_id, value, index, type = null, event = null) {

    let __index = this.ResponseData.findIndex(x => x.question_id == question_id);
    this.Step = question_id;

    if(type != 'select' || event.target.checked) {
      if(__index != -1) {
        // update the value
        this.ResponseData[__index]['answer'] = value;
      }
      else {
        let _response: ResponseModel = {
          parent_question_id: null,
          question_id: question_id,
          answer: value
        }

        this.ResponseData.push(_response);
      }
    }
    else if(type == 'select') {
      this.ResponseData.splice(__index, 1);
    }

    // get the next question id
    if(index != this.QuestionsData.length) {
      let next_question = this.QuestionsData[index];
      this.animateScrollService.scrollToElement('Question_'+next_question.question_id, 600);
    }
  }

  SelectNestedQuestionOption(question_id, nested_question_id, value, index) {
    let __index = this.ResponseData.findIndex(x => x.question_id == nested_question_id);

    if(__index != -1) {
      this.ResponseData[__index]['answer'] = value;
    }
    else {
      let _response: ResponseModel = {
        parent_question_id: question_id,
        question_id: nested_question_id,
        answer: value
      }

      this.ResponseData.push(_response);
    }

    // logic for scroll
    // get the total count of nested questions
    let __question_index = this.QuestionsData.findIndex(x => x.question_id == question_id);
    let _nested_question_count = this.QuestionsData[__question_index]['nested_questions'].length;

    // get response data count for the all selected questions
    var _nested_answeres =  this.ResponseData.filter(function(answer) {
      return answer.parent_question_id == question_id;
    });

    if(_nested_answeres.length == _nested_question_count) {
      // get the next question id
      if(index != this.QuestionsData.length) {
        let next_question = this.QuestionsData[index];
        this.animateScrollService.scrollToElement('Question_'+next_question.question_id, 600);
      }
    }
  }

  checkError(question_id, type = null) {
    let __index = this.ResponseData.findIndex(x => x.question_id == question_id);

    if(__index == -1 && type != 'nested') {
      // scroll up
      if(this.ScrollForError) {
        this.ScrollForError = false;
        this.animateScrollService.scrollToElement('Question_'+question_id, 600);
      }

      return true;
    }
    else if(type == 'nested') {

      // get the total count of nested questions
      let __question_index = this.QuestionsData.findIndex(x => x.question_id == question_id);
      let _nested_question_count = this.QuestionsData[__question_index]['nested_questions'].length;

      // get response data count for the all selected questions
      var _nested_answeres =  this.ResponseData.filter(function(answer) {
        return answer.parent_question_id == question_id;
      });

      if(_nested_answeres.length != _nested_question_count && this.ScrollForError) {
        this.ScrollForError = false;
        this.animateScrollService.scrollToElement('Question_'+question_id, 600);

        return true;
      }
    }

    return false;
  }

  CheckQualify(data) {
    this._DoIQualifyFormModel.QuestionsData = this.ResponseData;
    this.IsCheckNowClicked = true;
    this.ScrollForError = true;

    data.spa_id = this._AppGlobals.spa_id;
    data.activity_page = 'do i qualify';

    if(this.CheckFormValid()) {
      this.loading = true;
      console.log(this._DoIQualifyFormModel);
      // this.modalShow();
      this._DoIQualifyService.SaveCheckNow(data).subscribe(
        result => {
          this.SendMeApplication(this._DoIQualifyFormModel);
        }
      );
    }else{
      return;
    }
  }

  CheckFormValid() {
    for(let i = 0; i < this.QuestionsData.length; i++) {
      let _error = this.checkError(this.QuestionsData[i]['question_id'], this.QuestionsData[i]['type']);

      if(_error) {
        return false;
      }
    }

    return true;
  }

  SendMeApplication(data){
    let errors = this.FormErrors = this.validateForm();

    data.spa_id = this._AppGlobals.spa_id;

    if(this.FormValid==true){
      this._DoIQualifyService.SaveSendMeApplication(data).subscribe(
        result => {
          this.loading = false;
          if(result['success']==true){
            this.router.navigate(['/thank-you']);
          }
        }
      );
    }
    else{
      return;
    }
  }

  validateForm() {
  this.FormValid = true;
    let error: any = {
      "FullName": false,
      "Street": false,
      "City": false,
      "State": false,
      "ZipCode": false,
    };

    if(this._DoIQualifyFormModel.FullName == null || this._DoIQualifyFormModel.FullName=="") {
      this.FormValid = false;
      error.FullName = true;

    }
    else {
      error.FullName = false;
    }


    if(this._DoIQualifyFormModel.Street == null || this._DoIQualifyFormModel.Street=="") {
      // this.FormValid = false;
      // error.Street = true;
      error.Street = false;
      this._DoIQualifyFormModel.Street = 'Unnamed'

    }
    else {
      error.Street = false;
    }

    if(this._DoIQualifyFormModel.City == null || this._DoIQualifyFormModel.City=="") {
      this.FormValid = false;
      error.City = true;
    }
    else {
      error.City = false;
    }

    if(this._DoIQualifyFormModel.State == null || this._DoIQualifyFormModel.State=="") {
      this.FormValid = false;
      error.State = true;
    }
    else {
      error.State = false;
    }

    if(this._DoIQualifyFormModel.ZipCode == null || this._DoIQualifyFormModel.ZipCode=="") {
      this.FormValid = false;
      error.ZipCode = true;
    }
    else {
      error.ZipCode = false;
    }

    return error;
  }


}

class SendApplicationModel {
  public name : string = null;
  public street : string = null;
  public city : string = null;
  public state : string = null;
  public zipcode : string = null;
}

class ResponseModel {
  public parent_question_id: string = null;
  public question_id: string = null;
  public answer: string = null;
}
