import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadFormModel } from '../../models/LeadForm';
import { MenuState } from '../../models/MenuState';
import * as ouibounce from 'ouibounce';
import { QuoteService } from '../../services/quote.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  validZip = true;
  validDate = true;
  public page_title = "Loading...";utm_campaign: "LIRP-retarget"
  public sub_text = "Loading...";
  public QueryParams: any = null;

  title = 'ng-ouibounce';
  @ViewChild('modal', {static: false}) modal: ElementRef;
  
  ngAfterViewInit() {
    ouibounce(this.modal.nativeElement, { aggressive: true });
  }

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public FormValues: LeadFormModel = new LeadFormModel,
    public MenuStateValues: MenuState = new MenuState,
    public _QuoteService: QuoteService,
    public activateRoute: ActivatedRoute,
  ) {
    this.QueryParams = this.activateRoute.snapshot.queryParams;
    console.log(this.QueryParams);
    this.FormValues.utm_source  = this.QueryParams.utm_source;
    this.FormValues.utm_campaign  = this.QueryParams.utm_campaign;
    this.FormValues.utm_medium  = this.QueryParams.utm_medium;
    this.FormValues.utm_content  = this.QueryParams.utm_content;
    this.FormValues.utm_term  = this.QueryParams.utm_term;
    this.FormValues.referred  = window.location.href.split('?')[0];

   }

  ngOnInit() {
    // this.router.navigate([`/${usrType}/dashboard`]);
    window.scroll(0,0);

    this.getSPAContent();
  }

  getSPAContent() {
    this._QuoteService.GetSPAContent().subscribe(
      result => {
        this.page_title = result['page_title'];
        this.sub_text = result['sub_text'];
      }
    );
  }

  gotoSpa(selector2) {
    // if (!selector1.value) {
    //   this.validZip = false;
    // } else {
    //  this.validZip = true;
    // }
    
    this.validDate = false;
    if (selector2.value && selector2.value.length == 10) {
      this.validDate = true;
    }

    if(this.validZip && this.validDate) {
      this.router.navigate(['/get-quote']);
    }
  }



}
