import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { LeadFormModel } from '../../models/LeadForm';
import { Router } from '@angular/router';

@Component({
  selector: 'app-spa-form',
  templateUrl: './spa-form.component.html',
  styleUrls: ['./spa-form.component.css']
})
export class SpaFormComponent implements OnInit {

  constructor(
    private animateScrollService: NgAnimateScrollService,
    public FormValues: LeadFormModel = new LeadFormModel,
    private router: Router,
  ) {

    }

  public selected: boolean = true;
  public FormValid: boolean = false;
  public FormErrors: any = null;
  public Step: number = 0;

  ngOnInit() {
    window.scroll(0,0);
    this.scrollForCompletedFileds();
    this.checkDob();
  }

  scrollToDOB(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToDOB', duration);
    this.Step = 0;
  }

  scrollToFaceAmount(duration?:number){
    this.animateScrollService.scrollToElement('scrollToFaceAmount', duration);
    this.Step = 1;
  }

  scrollToGender(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToGender', duration);
    this.Step = 2;
  }

  scrollToSmoker(duration?:number){
    this.animateScrollService.scrollToElement('scrollToSmoker', duration)
    this.Step = 3;
  }
  
  scrollToMedications(duration?:number){
    this.animateScrollService.scrollToElement('scrollToMedications', duration)
    this.Step = 4;
  }

  scrollToWH(duration?:number) {
     this.animateScrollService.scrollToElement('scrollToWH', duration)
     this.Step = 5;
  }

  scrollToAddress(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToAddress', duration);
    this.Step = 6;
  }

  scrollToHealth(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToHealth', duration)
    this.Step = 7;
  }

  scrollToUserDetails(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToUserDetails', duration)
    this.Step = 8;
  }
  

  CheckInput(e,params){
    // if(params == 'zipcode'){
    //   let zipcode = e.target.value;
    //   if(zipcode != null && zipcode.length == 5){
    //     this.FormValues.setZipCode(zipcode);
    //   }
    // }else{
    //   let dob = e.target.value;
    //   if(dob.length == 10){
    //     this.FormValues.setDob(dob);
    //   }
    // }

    // if(this.FormValues.getDob().length == 5 && this.FormValues.getZipCode().length == 10){
    //   this.scrollToFaceAmount(600);
    // }
  }

  selectedFaceAmount(amount) {
    this.FormValues.setCoverageAmount(amount);
    this.scrollToGender(600);
  }
  selectedGender(gender) {
    this.FormValues.setGender(gender);
    this.scrollToSmoker(600);
  }

  isSomker(smoker){
    this.FormValues.setSmoker(smoker);
    this.scrollToMedications(600);
  }
  
  mediCation(medication){
    this.FormValues.setMedication(medication);
    this.scrollToWH(600);
  }

  // selectedWeightHeight(weightheight) {
  //   this.FormValues.setWeightHeight(weightheight);
  //   this.scrollToHealth(600);
  // }

  selectHealthCondition(event, value) {
    let _index = this.FormValues.HealthConditions.indexOf(value);

    if(event.target.checked && _index == -1) {
      if(value == 'None') {
        this.FormValues.resetHealthCondition();
      }
      else {
        // pop none out of health condition array
        let _none_index = this.FormValues.HealthConditions.indexOf('None');

        if(_none_index > -1) {
          this.FormValues.HealthConditions.splice(_none_index, 1);
        }
      }

      this.FormValues.setHealthCondition(value);
    }
    else {
      if(_index > -1) {
        this.FormValues.HealthConditions.splice(_index, 1);
      }
    }
  }

  handleAddressChange(event){
    let street_address = '';

    for(let i = 0; i < event.address_components.length; i++) {
      if(event.address_components[i].types != undefined) {
        for(let j = 0; j < event.address_components[i].types.length; j++) {
          if(event.address_components[i].types[j] == "postal_code") {
            this.FormValues.ZipCode = event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "locality") { // city
            this.FormValues.City = event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "administrative_area_level_1") { // state
             this.FormValues.State = event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "country") { // country
            this.FormValues.Country = event.address_components[i].long_name;
          }

          // for address
          if(event.address_components[i].types[j] == "street_number") { 
            street_address += ' ' + event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "route") { 
            street_address += ' ' + event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "sublocality_level_1") { 
            street_address += ' ' + event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "sublocality_level_2") { 
            street_address += ' ' + event.address_components[i].long_name;
          }
        }
      }
    }
    
    this.FormValues.Address = street_address;
    
     this.checkZip();
    //  this.checkDob();
  }

  CheckAddressInput(event){
    if(event.target.value==''){
      this.FormValues.ZipCode=null;
    }
    
  }

  checkDob() {
    if(this.FormValues.Dob != null) {
      this.scrollToFaceAmount(600);
    }
  }

  checkZip() {
    
    if(this.FormValues.ZipCode != null) {
      // this.scrollToFaceAmount(600);
      this.scrollToUserDetails(600);
    }
  }

  checkWeightHeight(value = null,which = null) {
    // if(which == 'HeightFeet'){
    //   this.FormValues.setHeightInFeet(value);
    // }else if(which == 'HeightInch'){
    //   if(!this.FormValues.HeightInFeet){
    //     this.FormValues.setHeightInFeet(4);
    //   }
    //   this.FormValues.setHeightInInch(value);
    // }
    
    if(this.FormValues.setWeight == null || this.FormValues.HeightInFeet == null || this.FormValues.HeightInInch == null ){
      return;
    }
    else if(this.FormValues.Weight != null && this.FormValues.HeightInFeet != null && this.FormValues.HeightInInch != null) {
      this.scrollToAddress(600);
    }
  }

  // unSelectAll(value){

  // }

  scrollForCompletedFileds() {
    if(this.FormValues.Weight != null && this.FormValues.HeightInFeet != null && this.FormValues.HeightInInch != null) {
      this.scrollToUserDetails(600);
    }
    else if(this.FormValues.Medication != null) {
      this.scrollToMedications(600);
    }
    else if(this.FormValues.Smoker != null) {
      this.scrollToWH(600);
    }
    else if(this.FormValues.Gender != null) {
      this.scrollToSmoker(600);
    }
    else if(this.FormValues.Term != null) {
      this.scrollToGender(600);
    }
    else if(this.FormValues.Dob != null) {
      this.scrollToFaceAmount(600);
    }
  }

  checkHealthConditionExists(value) {
    let _index = this.FormValues.HealthConditions.indexOf(value);
    if(_index > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  submitForm() {
    let errors = this.FormErrors = this.validateForm();
    
    if(
      !errors.zip_code &&
      !errors.dob &&
      !errors.coverage_amount &&
      !errors.gender &&
      !errors.smoker &&
      !errors.medication &&
      !errors.weight &&
      !errors.height_and_weight &&
      !errors.name &&
      !errors.contact_details
      // !errors.health_conditions
    ) {
      // this.router.navigate(['result', { queryParams: { utm_source: 'popular'} }]);
      this.router.navigate(['result']);
    }
    else if(errors.dob) {
      this.scrollToDOB(600);
    }
    else if(errors.coverage_amount) {
      this.scrollToFaceAmount(600);
    }
    else if(errors.gender) {
      this.scrollToGender(600);
    }
    else if(errors.smoker) {
      this.scrollToSmoker(600);
    }
    else if(errors.medication) {
      this.scrollToMedications(600);
    }
    // else if(errors.height_and_weight) {
    //   this.scrollToWH(600);
    // }
    else if(errors.zip_code) {
      this.scrollToAddress(600);
    }
  }

  validateForm() {
    this.FormValid = true;
    let error: any = {
      "zip_code": false,
      "dob": false,
      "coverage_amount": false,
      "gender": false,
      "smoker": false,
      "medication": false,
      "weight": false,
      "height_and_weight": false,
      "health_conditions": false,
      "name": false,
      "contact_details": false,
    };

    if(this.FormValues.ZipCode == null) {
      this.FormValid = false;
      error.zip_code = true;
    }
    else {
      error.zip_code = false;
    }

    if(this.FormValues.Dob == null) {
      this.FormValid = false;
      error.dob = true;
    }
    else {
      error.dob = false;
    }

    if(this.FormValues.CoverageAmount == null) {
      this.FormValid = false;
      error.coverage_amount = true;
    }
    else {
      error.coverage_amount = false;
    }

    if(this.FormValues.Gender == null) {
      this.FormValid = false;
      error.gender = true;
    }
    else {
      error.gender = false;
    }

    if(this.FormValues.Smoker == null) {
      this.FormValid = false;
      error.smoker = true;
    }
    else {
      error.smoker = false;
    }

    if(this.FormValues.Medication == null) {
      this.FormValid = false;
      error.medication = true;
    }
    else {
      error.medication = false;
    }

    if(this.FormValues.Weight == null) {
      this.FormValid = false;
      error.weight = true;
    }
    else {
      error.weight = false;
    }

    if(this.FormValues.HeightInFeet == null || this.FormValues.HeightInInch == null) {
      this.FormValid = false;
      error.height_and_weight = true;
    }
    else {
      error.height_and_weight = false;
    }

    if(this.FormValues.FirstName == null || this.FormValues.LastName == null) {
      this.FormValid = false;
      error.name = true;
    }
    else {
      error.name = false;
    }

    if(
      this.FormValues.Email == null || 
      this.FormValues.Email == '' || 
      !this.emailIsValid(this.FormValues.Email) ||
      this.FormValues.PhoneNumber == null || 
      this.FormValues.PhoneNumber == '' || 
      !this.phoneIsValid(this.FormValues.PhoneNumber)
    ) {
      this.FormValid = false;
      error.contact_details = true;
    }
    else {
      error.contact_details = false;
    }
    
    return error;
  }

  emailIsValid (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  phoneIsValid (phone) {
    return phone.length == 10 ? true : false;
  }

  // UnSelectAll(){
  //   let items=document.getElementsByName('any');
    
  //   for(let i=0; i<items.length; i++){
  //     if(items[i].type=='checkbox')
  //       items[i].checked=false;
  //   }
  // }

}
